/*
* ===================================================
*     Sidebar Modal 
* ===================================================
*/
$header-nav-height: 0px; // 56px;
$modal-height: calc(100vh - #{$header-nav-height});

.modal {

    &.modal-left,
    &.modal-right {
        .modal-dialog {
            max-width: 500px;
            min-height: $modal-height;
        }

        &.show .modal-dialog {
            transform: translate(0, 0);
        }

        .modal-content {
            overflow-x: hidden;
            overflow-y: auto;
            height: $modal-height;
            border-radius: 0;
        }
    }

    &.modal-left {
        .modal-dialog {
            margin: $header-nav-height auto 0 0;
            transform: translate(-100%, 0);
        }
    }

    &.modal-right {
        padding-right: 0 !important;

        .modal-dialog {
            margin: $header-nav-height 0 0 auto;
            transform: translate(100%, 0);
        }
    }
}