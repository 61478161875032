/*
* ===================================================
*    Customer section
* ===================================================
*/

.customer-profile {
    padding: 2rem;
    text-align: center;
    background: $gray-100;
}

.customer-image {
    max-width: 10rem;
    margin-bottom: 1.5rem;
    padding: 0.5rem;
    background: #fff;
}

.customer-nav {
    .list-group-item {
        color: $gray-700;
        border-color: $gray-200;
        font-size: 0.9rem;
        @include hover-focus {
            text-decoration: none;
            background: $gray-100;
        }
        &.active {
            border-color: $gray-300;
            background:  $gray-300;
        }
    }

    .svg-icon {
        position: relative;
        top: -2px;
    }
}
