/*
* ===================================================
*     Docs
* ===================================================
*/
.docs-item {
    margin-bottom: .5rem;
    padding: 2.5rem 0;
    border-bottom: 1px solid $gray-300;

    &:first-of-type {
        padding-top: 0;
    }

    &:last-of-type {
        border-bottom: none;
    }

    .docs-desc {
        //font-size: $font-size-lg;
        color: $gray-600;

        p.lead,
        strong,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $body-color;
        }
    }
}