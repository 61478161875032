/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2019 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      ABSTRKT
 * License URL: https://www.fontspring.com/licenses/abstrkt/webfont
 *
 *
 */

@font-face {
    font-family: 'vremenagrotesk';
    src: url('vremenagroteskbook-webfont.woff2') format('woff2'),
         url('vremenagroteskbook-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'vremenagrotesk';
    src: url('vremenagrotesk-webfont.woff2') format('woff2'),
         url('vremenagrotesk-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'vremenagrotesk';
    src: url('vremenagroteskbold-webfont.woff2') format('woff2'),
         url('vremenagroteskbold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

